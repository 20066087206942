import React from "react"
import { Link } from "gatsby"

import { sections } from "../utils/stats"
import Pokemon from "../templates/pokemon/pokemonCard"
import pokemons from "../utils/raids.json"



export default ({ images }) => (
  <div style={{ width: "100%", color: "#424242" }}>
    <h1
      className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
      style={{ textAlign: "center", fontWeight: "bold" }}
    >
      Current Raid Bosses
    </h1>

    <h2>What raid bosses are in Pokemon go?</h2>

    <p>
      A Raid Boss is a Pokémon that takes over a Gym for 1 to 2hours. During
      this time your gym is not accessible for fighting and you can't feed
      berries to your Pokemon defending the gym. The raid boss is a lot stronger
      than its normal version you can encounter in the wild. You'll need help
      from other trainers to defeat this stronger pokemon in order to win a few
      prices (such as TM, berries and so on) and to have a chance of catching
      it.
    </p>


    <h2>Pokemon GO raid boss September 2020</h2>
    <p>Here is the complete list of September 12 raid bosses:</p>

    <div>
      {Object.keys(pokemons.current).map(level => {
        const raids = pokemons.current[level].map(raid => ({
          ...raid,
          type1: raid.type[0].toLowerCase(),
          type2: raid.type.length === 2 ? raid.type[1].toLowerCase() : null
        }))
        return (
          <div key={level}>
            {raids.length ? <h3>{sections[level]}</h3> : ""}
            <div>
              {raids.map(pokemon => (
                <Link
                  key={`${pokemon.id} - ${pokemon.form}`}
                  to={`/pokemon-go/${
                    pokemon.id
                  }-${pokemon.name.toLowerCase()}/`}
                >
                  <Pokemon
                    pokemon={pokemon}
                    image={images.find(
                      raw => pokemon.id === raw.node.frontmatter.id
                    )}
                  />
                </Link>
              ))}
            </div>
          </div>
        )
      })}
    </div>

    <h2>FAQ about raid bosses in Pokemon GO</h2>

    <h2>Why are there no raids in Pokemon go at night?</h2>
    <p>
      Raids will start early in the moring (around 7a.m.) and stop in the
      evening (around 9p.m.). As most raids occur at specific public places and
      business. In order to decrease the number of incidents and degradations at
      those place, it's fair bet to think that Niantic didn't want for Pokémon
      GO to have an even worst press if anything happened. This is why Pokémon
      GO raid bosses stop at night.
    </p>

    <h2>What is the next raid boss in Pokemon go?</h2>
    <p>
      The next raid boss rotation date is still unknown but be sure to see it
      first on this page!
    </p>

    <h2>What is the ex raid boss September 2020?</h2>
    <p>
      There are no EX raid boss as of March 2020. As EX raid boss require
      interaction between trainers in real life and because of Covid-19
      Coronavirus,
      <Link to="https://twitter.com/NianticHelp/status/1238588705096364032">
        Niantic announced on Twitter that they decided to halt EX raid bosses
        since then
      </Link>
      .
    </p>

    <h2>Who is an ex raid boss?</h2>
    <p>
      EX raid boss or Exclusive Raid Boss are legendary raid bosses but invite
      only.
    </p>
    <p>Past EX Raid bosses include:</p>
    <ul>
      <li>
        <Link to="/pokemon-go/486-regigigas/">Regigigas</Link>
      </li>
      <li>
        <Link to="/pokemon-go/150-mewtwo/">Mewtwo</Link> with Shadow Ball
      </li>
      <li>
        <Link to="/pokemon-go/386-deoxys/">Deoxys</Link> Speed Forme
      </li>
      <li>
        <Link to="/pokemon-go/386-deoxys/">Deoxys</Link> Defense Forme
      </li>
      <li>
        <Link to="/pokemon-go/386-deoxys/">Deoxys</Link> Attack Forme
      </li>
      <li>
        <Link to="/pokemon-go/386-deoxys/">Deoxys</Link> Normal Forme
      </li>
      <li>
        <Link to="/pokemon-go/150-mewtwo/">Mewtwo</Link>
      </li>
    </ul>

    <h2>How do you get invited to ex raids?</h2>
    <p>
      Eligibility criterias are still quite vague but those raid battles you'll
      need to regularly visit an EX Gym (you can see the label on gyms affected)
      and be worthy enough to get an invitation: an EX raid pass.
    </p>

    <h2>Can you solo raid bosses in Pokemon go?</h2>
    <p>
      Depending on your level, you can beat a Raid Boss up to 2-3 stars own your
      own(representing the difficulty, the maximum stars, for legendaries, being
      5). It's recommended to choose the right Pokemons and fight in teams with
      other fellow trainers! This app helps you for the first part, select the
      right mons to counter the raid boss.
    </p>

    <h2>Can you solo a level 1 RAID?</h2>
    <p>
      Yes, level 1 raid bosses are fairly easy to beat on your own with simple
      Pokemons
    </p>

    <h2>Can I beat a level 2 raid by myself?</h2>
    <p>
      Yes, you can beat a level 2 star raid by yourself with Pokémon of level 30
      or more. It's going to be even easier if you pay attention to the
      weaknesses of the raid boss you're trying to beat, if you're not sure what
      Pokemons to use, take a look at our{" "}
      <Link to="/pokemon-go-type-chart/">Pokémon GO Type Chart</Link> guide.
    </p>

    <h2>Can you beat a level 3 raid by yourself?</h2>
    <p>
      Yes, most 3 stars raid bosses is the last level of raids you can
      potentialy beat. However you'll still need Pokémon level at least 35+ to
      have a chance. Also, be sure to take super effective Pokemons taking a
      look at our <Link to="/pokemon-go-type-chart/">Pokémon GO Type Chart</Link>{" "}
      guide. For instance, you can solo an{" "}
      <Link to="/pokemon-go/142-aerodactyl/">Aerodactyl</Link> raid with Pokémon
      such as <Link to="/pokemon-go/376-metagross/">Metagross</Link>.
    </p>

    <h2>Can you solo a 4 star raid?</h2>
    <p>
      No, you can not solo a 4 star raid. We recommend at least 3 or 4 players
      to beat easily a raid boss of that level. For instance,{" "}
      <Link to="/pokemon-go/131-lapras/">Lapras</Link> is a level 4 raid boss and
      can not be solo-ed.
    </p>

    <h2>Can you beat a 5 star raid by yourself?</h2>
    <p>
      Don't even dare trying, it's impossible to beat a Level 5 raid boss or
      more
    </p>

  </div>
)
