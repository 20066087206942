import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Raids from "../components/raids"

const RaidsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const images = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Pokémon GO Current Raid Bosses"
        description="Raids can be challenging but are to only way to catch some Pokémon. Stop losing and discover what are the current raid bosses and how to beat them!"
        slug={location.pathname}
      />
      <Raids images={images} />
    </Layout>
  )
}

export default RaidsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: {
          regex: "/.*/pokemon/(100|524|215|138|37|299|557|74|221|142|135|105|365|306|112|76|144|3|9|6|6|18)-.*/"
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
